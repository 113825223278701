import React, { useContext } from 'react';
import { ModalContext, IModalContext } from 'contexts/modal';
import formatCurrency from 'utils/format-currency';
import ArrowIcon from 'components/icon/arrow-icon';
import CloseIcon from 'components/icon/close-icon';
import { buildClassName } from 'utils/build-class-name';
import { useIsMobile } from 'hooks/use-size-class';
import styles from './style.module.scss';

import type Listing from 'data/listing';

interface Props {
  listing: Listing;
  isLightbox?: boolean;
  activeImage?: number;
}

export default function TopBar({ listing, isLightbox, activeImage }: Props) {
  const { closeModal } = useContext(ModalContext) as IModalContext;
  const { price, soldPrice, seoStreet, avgSqft, bathrooms, bedrooms } = listing;
  const isMobile = useIsMobile();

  const closePhotoGalleryAndLightbox = () => {
    if (isLightbox) {
      closeModal(); closeModal();
    } else {
      closeModal();
    }
  };

  return (
    <div className={buildClassName(styles.component, isLightbox && styles.lightbox)}>
      <div className={styles.content}>
        {!isMobile && <ArrowIcon onClick={closeModal} />}
        <div>
          {soldPrice && <h3>{formatCurrency(soldPrice)}</h3>}
          {price &&
            <h3 className={buildClassName(!!soldPrice && styles['crossed-price'])}>
              {!!soldPrice && !isMobile && 'List Price'} <span>{formatCurrency(price)}</span>
            </h3>
          }
          {!isMobile && <p>{seoStreet}</p>}
          {isMobile && <p>{bedrooms}bd, {bathrooms}ba, {avgSqft} sqft</p>}
        </div>
      </div>
      <div className={styles.right}>
        {isLightbox && typeof activeImage === 'number' &&
          <p className={styles.count}>{activeImage + 1}/{listing.imageUrls.length}</p>
        }
        <CloseIcon className={styles.close} onClick={closePhotoGalleryAndLightbox} />
      </div>
    </div>
  );
}
