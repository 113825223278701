import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './style.module.scss';
import LoadWhenVisible from 'components/load-when-visible';
import { mapPageIds } from 'constants/test-constants';
import AppleMap from '@zoocasa/node-kit/map/apple/map';
import SatelliteControl from 'components/search-es/map/SatelliteControl';
import { drawInitialMap } from 'utils/apple-maps';
import { trackEvent } from 'utils/google-tag-manager';
import {
  GTM_CLICK_ADDRESS_MODAL_MAP_SATELLITE_VIEW, GTM_CLICK_ADDRESS_MODAL_MAP_STREET_VIEW,
  GTM_CLICK_ADDRESS_PAGE_MAP_SATELLITE_VIEW, GTM_CLICK_ADDRESS_PAGE_MAP_STREET_VIEW,
} from 'constants/events';
import { useThemeContext } from 'contexts';

import type { ThemeNames } from 'types/themes';

export interface GeoJSONPoint {
  type: 'Point';
  coordinates: [number, number];
}

interface Props {
  className?: string;
  listingId: number | string;
  trackEventLocation?: TrackEventLocation;
  position: GeoJSONPoint;
  isInteractive?: boolean;
  loadWhenVisible?: boolean;
}

export enum TrackEventLocation {
  Modal = 'Modal',
  Page = 'Page',
}

export default function Map({ className, position, listingId, trackEventLocation, isInteractive, loadWhenVisible = true }: Props) {
  const mapElementId = `listing-map-${listingId}`;
  const [mapShouldLoad, setMapShouldLoad] = useState(false);
  const mapRef = useRef<AppleMap | null>(null);
  const { themeName } = useThemeContext();

  const handleSatelliteAndStreetViewToggled = useCallback(() => {
    if (mapRef?.current !== null) {
      if (mapRef.current?.map.mapType === mapkit.Map.MapTypes.Standard) {
        mapRef.current.map.mapType = mapkit.Map.MapTypes.Satellite;
        trackSatelliteViewEventByLocation();
        return mapkit.Map.MapTypes.Satellite;
      } else if (mapRef.current?.map.mapType === mapkit.Map.MapTypes.Satellite) {
        mapRef.current.map.mapType = mapkit.Map.MapTypes.Standard;
        trackStreetViewEventByLocation();
        return mapkit.Map.MapTypes.Standard;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackSatelliteViewEventByLocation = () => {
    if (trackEventLocation === TrackEventLocation.Page) {
      trackEvent(GTM_CLICK_ADDRESS_PAGE_MAP_SATELLITE_VIEW);
    } else if (trackEventLocation === TrackEventLocation.Modal) {
      trackEvent(GTM_CLICK_ADDRESS_MODAL_MAP_SATELLITE_VIEW);
    }
  };

  const trackStreetViewEventByLocation = () => {
    if (trackEventLocation === TrackEventLocation.Page) {
      trackEvent(GTM_CLICK_ADDRESS_PAGE_MAP_STREET_VIEW);
    } else if (trackEventLocation === TrackEventLocation.Modal) {
      trackEvent(GTM_CLICK_ADDRESS_MODAL_MAP_STREET_VIEW);
    }
  };

  useEffect(() => {
    if (mapShouldLoad) {
      drawInitialMap(mapElementId, position, isInteractive, themeName as ThemeNames)
        .then(mapInstance => {
          mapRef.current = mapInstance;
        });
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapShouldLoad]);

  useEffect(() => {
    if (!loadWhenVisible) {
      setMapShouldLoad(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (loadWhenVisible ?
    <LoadWhenVisible onValueChange={() => setMapShouldLoad(true)}>
      <div className={styles.component}>
        <SatelliteControl className={styles['custom-position']} onViewToggle={handleSatelliteAndStreetViewToggled} />
        <div id={mapElementId} className={className} data-testid={mapPageIds.listingMap}/>
      </div>
    </LoadWhenVisible>
    :
    <div className={styles.component}>
      <SatelliteControl className={styles['custom-position']} onViewToggle={handleSatelliteAndStreetViewToggled} />
      <div id={mapElementId} className={className} data-testid={mapPageIds.listingMap}/>
    </div>
  );
}
